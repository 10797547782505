import eventBus from '../helpers/event-bus';
import exchangeFormEventNames from '../exchange-form/exchange-form-event-names';
import RatesService from '../services/rates';
import DeferredPromise from '../helpers/deferredPromise';

let isExchangeRatesLoading = false;

export const eventNames = {
  EVENT_GLOBAL_STORAGE_INITIATED: 'global-storage.initiated',
  EVENT_EXCHANGE_FORM_DATA_CHANGED: 'global-storage.exchange-form-data-changed',
  EVENT_RELATION_CHANGED: 'global-storage.relation-changed',
  EVENT_FIAT_CURRENCY_CHANGED: 'global-storage.fiat-currency-changed',
  EVENT_CRYPTO_CURRENCY_CHANGED: 'global-storage.crypto-currency-changed',
  EVENT_EXCHANGE_RATE_CHANGED: 'global-storage.exchange-rate-changed',
  EVENT_RELATION_NOT_CHANGED: 'global-storage.relation-not-changed',
};

const deferredIsReadyPromise= new DeferredPromise();
export const GlobalStorage = {
  isInitiated: false,
  exchangeRates: {},
  relationLink: null,
  serviceFromId: null,
  serviceToId: null,
  fiatCurrencyCode: null,
  cryptoCurrencyCode: null,
  cryptoAssetId: null,
  isServiceFromCrypto: null,
  isServiceToCrypto: null,
  isExtendedExchangeRatesReady: false,
  isReady: deferredIsReadyPromise.promise,

  init() {
    const transactionData = document.querySelector('[data-transaction]').dataset.transaction || '{}';
    const exchangeRates = document.querySelector('[data-exchange-rates]').dataset.exchangeRates || '{}';
    const {
      relation = {},
      serviceFrom = {},
      serviceTo = {},
      currency = 'USD',
    } = JSON.parse(transactionData);

    let cryptoMoneyService = serviceTo;
    if (serviceFrom.payment_group === 'crypto') {
      cryptoMoneyService = serviceFrom;
    }

    const { instant_exchange_amounts: instantExchangeAmounts } = relation;

    this.fiatCurrencyCode = currency || 'USD';
    this.cryptoCurrencyCode = cryptoMoneyService.cryptocurrency || null;
    this.cryptoAssetId = cryptoMoneyService.asset || null;
    this.relationLink = relation.buy_link;
    this.serviceFromId = serviceFrom.id;
    this.serviceToId = serviceTo.id;
    this.isServiceFromCrypto = serviceFrom.payment_group === 'crypto';
    this.isServiceToCrypto = serviceTo.payment_group === 'crypto';
    this.instantExchangeBlockAmountList = instantExchangeAmounts;
    this.exchangeRates = JSON.parse(exchangeRates);

    this.initEventListeners();

    eventBus.emit(eventNames.EVENT_GLOBAL_STORAGE_INITIATED, {
      fiatCurrencyCode: this.fiatCurrencyCode,
      cryptoCurrencyCode: this.cryptoCurrencyCode,
      cryptoAssetId: this.cryptoAssetId,
      relationLink: this.relationLink,
      serviceFromId: this.serviceFromId,
      serviceToId: this.serviceToId,
      isServiceFromCrypto: this.isServiceFromCrypto,
      isServiceToCrypto: this.isServiceToCrypto,
      exchangeRates: this.exchangeRates,
    })
    this.isInitiated = true;
    deferredIsReadyPromise.resolve();
  },
  initEventListeners() {
    eventBus.on(
      exchangeFormEventNames.FORM_CHANGED_SERVICES,
      (payload) => this.setExchangeFormData(payload),
    );
  },
  setExchangeFormData(payload) {
    const {
      fiatCurrencyCode,
      cryptoCurrencyCode,
      cryptoAssetId,
      relationLink,
      serviceFromId,
      serviceToId,
      isServiceFromCrypto,
      isServiceToCrypto,
    } = payload;
    this.fiatCurrencyCode = fiatCurrencyCode;
    this.cryptoCurrencyCode = cryptoCurrencyCode;
    this.cryptoAssetId = cryptoAssetId;
    this.relationLink = relationLink;
    this.serviceFromId = serviceFromId;
    this.serviceToId = serviceToId;
    this.isServiceFromCrypto = isServiceFromCrypto;
    this.isServiceToCrypto = isServiceToCrypto;

    // TODO: New exchange form need it
    eventBus.emit(eventNames.EVENT_EXCHANGE_FORM_DATA_CHANGED, payload);
  },
  leadExchangeRates() {
    if (isExchangeRatesLoading) {
      return Promise.reject();
    }

    isExchangeRatesLoading = true;
    return RatesService.getFiatRates().then((rates) => {
      this.exchangeRates = rates;
      this.isExtendedExchangeRatesReady = true;
      isExchangeRatesLoading = false;
    });
  },
};
