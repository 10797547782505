import api from './api';

import { GlobalStorage } from '../home-page/global-storage';

const client = {
  getLicensesBlock() {
    return this.getBlock('licenses');
  },
  async getCtaFull() {
    const params = {};
    const ctaFlow = await this.getCtaFlow();
    if (ctaFlow) {
      params.flow = ctaFlow;
    }
    return this.getBlock('cta-full', params);
  },
  async getCtaBlock() {
    const params = {};
    const ctaFlow = await this.getCtaFlow();
    if (ctaFlow) {
      params.flow = ctaFlow;
    }
    return this.getBlock('cta-block', params);
  },
  getQuickGuideBlock() {
    return this.getBlockFoSelectedRelation('quick-guide');
  },
  getFeaturesBlock() {
    return this.getBlockFoSelectedRelation('features');
  },
  getLatestTransactionsBlock() {
    return this.getBlock('latest-transactions');
  },
  getNewsBlock() {
    return this.getBlock('news');
  },
  getTrustpilotBlock() {
    return this.getBlock('trustpilot');
  },
  getBlockFoSelectedRelation(path) {
    return this.getBlock(path, {
      link: GlobalStorage.relationLink,
    });
  },
  getWalletSecurity() {
    return this.getBlock('wallet-security');
  },
  getWalletFunctionalityList() {
    return this.getBlock('wallet-functionality');
  },
  getWalletTrustpilot() {
    return this.getBlock('wallet-trustpilot');
  },
  getWalletFees() {
    return this.getBlock('wallet-fees');
  },
  getBlock(path, params = null) {
    return api
      .get(`/lazy-block/${path}/`, {
        params,
      })
      .then(({ data }) => data);
  },
  getPublicOnrampTrustpilot() {
    return this.getBlock('public-onramp-trustpilot');
  },
  async getCtaFlow(){
    let flow = null;
    await GlobalStorage.isReady;
    if (GlobalStorage.isServiceFromCrypto) flow = 'sell-crypto';
    if (GlobalStorage.isServiceToCrypto) flow = 'buy-crypto';
    return flow;
  },
};

const blocks = {
  licenses: () => client.getLicensesBlock(),
  ctaBlock: () => client.getCtaBlock(),
  features: () => client.getFeaturesBlock(),
  trustpilot: () => client.getTrustpilotBlock(),
  news: () => client.getNewsBlock(),
  latestTransactions: () => client.getLatestTransactionsBlock(),
  quickGuide: () => client.getQuickGuideBlock(),
  ctaFull: () => client.getCtaFull(),
  walletSecurity: () => client.getWalletSecurity(),
  walletFunctionalityList: () => client.getWalletFunctionalityList(),
  walletTrustpilot: () => client.getWalletTrustpilot(),
  walletFees: () => client.getWalletFees(),
  publicOnrampTrustpilot: () => client.getPublicOnrampTrustpilot(),
};

export default {
  getBlockHtml(blockName) {
    return blocks[blockName]()
      .then(({ html }) => html);
  },
};
